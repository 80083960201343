<template>
<maintenance />
</template>

<script>
import Maintenance from "./components/Maintenance.vue";

export default {
    name: "App",
    components: {
        Maintenance,
    },
};
</script>
