<template>
<article class="text-left">
    <h1 class="text-5xl font-black">We&rsquo;ll be back soon!</h1>
    <br />
    <div class="text-lg">
        <p>
            Sorry for the inconvenience but we&rsquo;re performing some maintenance at
            the moment. If you need to you can always
            <a :href="'mailto:'+email">contact us</a>, otherwise
            we&rsquo;ll be back online shortly!
        </p>
        <br/>
        <p>&mdash; {{ name }}</p>
    </div>
</article>
</template>

<script>
export default {
    name: "Maintenance",
    data() {
        return {
            name: "Bhupend Patil",
            email: "bhupend@blackmorph.com",
            color: "#54ade0"
        };
    },
};
</script>

<style lang="scss">
article {
    /* text-align: left; */
    padding: 130px 4px 30px 10px;
    font: 20px Helvetica, sans-serif;
    color: #333333;
    display: block;
    margin: 0 auto;
}

article>div>p>a {
    color: v-bind(color);
    text-decoration: none;

    &:hover {
        color: v-bind(color);
        text-decoration: none;
    }
}

@media (min-width: 576px) {
    article {
        width: 650px;
    }
}
</style>
